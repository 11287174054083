$theme: ekonomi;
@use "design-system" as ds;

.wikipediaContainer {
  @include ds.pad-lr();
  @include ds.stack();

  &.explaining,
  &.longform & {
    @include ds.stack(regular-increased);
    @media (min-width: ds.$screen-size--small) {
      @include ds.pad-lr(medium);
    }
  }
}

.innerWikipediaContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &.news {
    @include ds.stack();
    @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
  }
}

.icon {
  margin-right: -10px;
}

.contents {
  @include ds.pad-lr();
}

.labelAndTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.label {
  text-transform: uppercase;
  @include ds.typography(h6, $theme);
  @include ds.text-color(secondary, $theme: $theme);
  line-height: 21px;
}

.title {
  @include ds.typography(h5, $theme);
  @include ds.text-color(brand, $theme: $theme);
}

.source {
  @include ds.typography(underline, $theme);
  @include ds.text-color(secondary, $theme: $theme);
}

.text {
  @include ds.typography(h7, $theme);
  @include ds.text-color(primary, $theme: $theme);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
