$theme: ekonomi;
@use "design-system" as ds;

.illustration {
  display: inline-block;
  @include ds.colorise-icon(ui, brand, $theme: $theme);

  & svg {
    overflow: visible;
    pointer-events: all;
  }
}

$sizes: (
  "small": 64px,
  "normal": 120px,
  "large": 200px,
);

@each $name, $size in $sizes {
  .#{$name} {
    width: $size;
    height: $size;
    line-height: $size;
  }
}

@each $name, $size in $sizes {
  .#{$name}-upscale {
    @media (min-width: ds.$screen-size--small) {
      width: $size * ds.$desktop-upscaling-factor;
      height: $size * ds.$desktop-upscaling-factor;
      line-height: $size * ds.$desktop-upscaling-factor;
    }
  }
}
