$theme: ekonomi;
@use "design-system" as ds;

.thumbnailContainer {
  @include ds.stack();
  @include ds.pad-lr();
  position: relative;
  cursor: pointer;
  width: 100%;
  display: block;
  box-sizing: border-box;
  overflow: hidden; // For caption animation clipping
  aspect-ratio: 16 / 9;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:hover > .captionContainer {
    @include ds.text-color(tertiary, $theme: $theme);
    visibility: visible;
    opacity: 0.9;
    bottom: 0;
  }

  &.licensedArticle {
    @include ds.background(tertiary, $theme: $theme);
    @include ds.text-color(tertiary, $theme: $theme);
  }

  &.explaining,
  &.longform {
    @media (min-width: ds.$screen-size--small) {
      @include ds.pad-lr(medium);
    }

    &:not(.grouped) {
      padding-left: 0;
      padding-right: 0;
      img {
        border-radius: 0;
      }
    }
  }

  &.news {
    &.mainVideo {
      padding: 0;
      @include ds.stack(none);
      img {
        border-radius: 0;
      }
    }
    &:not(.first) {
      @include ds.stack();
    }
  }

  &.noPadding {
    padding: 0;
  }
}

.thumbnailImage  {
  width: 100%;
  height: auto;
}

.imageButton {
  position: absolute;
  bottom: 28px;
  left: 28px;
  text-align: center;
  border-style: none;
  background: transparent;
  cursor: inherit;
}

.videoPlayer {
  @include ds.stack();
  @include ds.pad-lr();

  &.licensedArticle {
    @include ds.background(tertiary, $theme: $theme);
    @include ds.text-color(tertiary, $theme: $theme);
  }

  &.explaining,
  &.longform {
    @media (min-width: ds.$screen-size--small) {
      @include ds.pad-lr(medium);
    }

    &:not(.grouped) {
      padding-left: 0;
      padding-right: 0;
      img {
        border-radius: 0;
      }
    }
  }

  &.news {
    &.mainVideo {
      padding: 0;
      @include ds.stack(none);
      img {
        border-radius: 0;
      }
    }
    &:not(.first) {
      @include ds.stack();
    }
  }

  & iframe {
    display: block;
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  box-sizing: border-box;
}

.captionContainer {
  @include ds.stack();
  @include ds.text-color(secondary, $theme: $theme);
  @include ds.typography(underline, $theme);

  &:not(.grouped) {
    // only pad when not grouped
    @include ds.pad-lr(regular);

    &.explaining,
    &.longform {
      @media (min-width: ds.$screen-size--small) {
        @include ds.pad-lr(medium);
      }
    }
  }

  &.captionOnHover {
    // For images that shouldn't show the caption upfront
    position: relative;
    overflow: hidden;
    visibility: hidden;

    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    @include ds.background(tertiary, $theme: $theme);
    @include ds.text-color(tertiary, $theme: $theme);
    @include ds.pad();

    opacity: 0;

    transition:
      visibility 0s,
      opacity 250ms 1s,
      bottom 250ms 1s;
    transition-delay: 1s;

    &.grouped {
      @media (min-width: ds.$screen-size--small) {
        margin: 0 ds.spacing(medium);
      }
    }
  }
}

.caption {
  @include ds.text-color(secondary, $theme: $theme);
  @include ds.typography(underline, $theme);
  margin-top: ds.spacing("micro");

  &:not(.grouped) {
    // only pad when not grouped
    @include ds.pad-lr(none);
  }
}
