$theme: ekonomi;
@use "design-system" as ds;

.adContainer {
  display: flex;
  justify-content: center;
  margin-top: ds.spacing(base);
  @include ds.border(divider--subtle, $t: 1px, $b: 1px, $theme: $theme);

  @media (min-width: ds.$screen-size--small) {
    display: none;
  }
}

.adContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}
