$theme: ekonomi;
@use "design-system" as ds;

.storyBox {
  @include ds.background(card--subtle, $theme: $theme);
  padding-left: ds.spacing(base);
  padding-right: ds.spacing(base);

  @media (min-width: #{ds.$screen-size--small}) {
    padding-left: 0;
    padding-right: 0;
  }

  &.native {
    @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
  }
}

.teasersContainer {
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
  padding: ds.spacing() 0;

  @media (min-width: ds.$screen-size--small) {
    padding: ds.spacing(medium) 0;
    margin: 0 ds.spacing();
  }
}

.separator {
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
  margin: 0 ds.spacing() ds.spacing() ds.spacing();
}
