$theme: ekonomi;
@use "design-system" as ds;

.wideScreenAdAnchor {
  height: auto;
  position: relative;

  &.bottomGradient {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
      @include ds.background-gradient-transparent-to(secondary, $theme: $theme);
      pointer-events: none;
    }
  }
}

.widescreenAd {
  position: sticky;
  //Size of the header + 8px padding
  top: calc(ds.$header-height--largescreen + ds.spacing(base));

  flex-grow: 0;
  flex-shrink: 0;
  display: none;
  padding-bottom: 1px;

  @media (min-width: ds.$screen-size--medium) {
    display: flex;
    flex-direction: column;
  }
}
