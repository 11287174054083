$theme: ekonomi;
@use "design-system" as ds;

.dotsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.dot {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: ds.color(ui, brand, $theme: $theme);
  border-radius: 50%;
  animation: blinking 1.4s infinite both;
}

.dotsContainer .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dotsContainer .dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes blinking {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
