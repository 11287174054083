$theme: ekonomi;
@use "design-system" as ds;

.menuItem {
  @include ds.background(primary, $theme: $theme);
  @include ds.border(divider--subtlest, $t: 1px, $theme: $theme);
  padding: ds.spacing() ds.spacing(regular-increased);
  @include ds.typography(h7, $theme, $responsive: false);
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ds.spacing(base);
  cursor: pointer;
  @include ds.text-color(primary, $theme: $theme);

  &:hover {
    @include ds.text-color(brand--prominent, $theme: $theme);
    &.itemNav {
      @include ds.colorise-icon(text, brand--prominent, $theme: $theme);
    }

    .caretIcon {
      @include ds.colorise-icon(ui, brand, $theme: $theme);
    }

    .badge {
      @include ds.background(brand, $namespace: ui, $theme: $theme);
      @include ds.text-color(tertiary, $theme: $theme);
    }
  }
}

.premium {
  @include ds.text-color(premium, $theme: $theme);
}

.schibstedLogo {
  display: block;
  width: 61px;
  height: 12px;
  text-indent: -9999px;
  position: relative;
  overflow: hidden;
  background-image: url("/images/schibsted-logo.png");
  @include ds.darkmode {
    background-image: url("/images/schibsted-logo-darkmode.png");
  }
  background-size: contain;
  background-repeat: no-repeat;
}

.itemNav {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  justify-self: flex-end;
  gap: ds.spacing(base);
  color: ds.color(ui, divider--subtle, $theme: $theme);
}

.caretIcon {
  @include ds.colorise-icon(ui, divider, $theme: $theme);
}

.button {
  width: 100%;
}

.badge {
  padding: ds.spacing(atomic) ds.spacing();
  border-radius: 100vi;
  @include ds.typography(underline, $theme, $responsive: false);
  @include ds.background(form-field, $namespace: ui, $theme: $theme);
  @include ds.text-color(secondary, $theme: $theme);
}
