$theme: ekonomi;
@use "design-system" as ds;

.linkContainer {
  @include ds.pad-lr();
  @include ds.stack();
  @include ds.typography(h3, $theme);
  text-wrap: pretty;
  & a {
    @include ds.text-color(primary, $theme: $theme);
    font-weight: 600;
  }

  &.news {
    padding: 0 ds.spacing();
    margin-top: ds.spacing();
  }

  &.longform,
  &.explaining {
    @include ds.stack(regular-increased);
    @media (min-width: ds.$screen-size--small) {
      @include ds.pad-lr(medium);
    }
  }

  &.native {
    @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
  }
}

.innerLinkContainer {
  &.news {
    @include ds.stack();
    @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
  }

  &.native {
    padding-bottom: ds.spacing();
  }
}

.sponsoredLabel {
  @include ds.typography(overline, $theme);
  text-transform: uppercase;
}

.titleText {
  @include ds.border(brand, $b: 1px, $theme: $theme);
  &.news {
    border: none;
  }
  &:hover {
    @include ds.text-color(brand, $theme: $theme);
  }
  &.premiumTitleText {
    @include ds.border(premium, $b: 1px, $theme: $theme);
    &:hover {
      @include ds.text-color(premium, $theme: $theme);
    }
  }
  &.native {
    margin-top: 4px;
    border: none;
  }
}

.arrow {
  display: inline;
  position: relative;
  top: 1px;
  margin-left: 2px;
}

.icon {
  @include ds.colorise-icon(ui, brand, $theme: $theme);
  &.premiumIcon {
    @include ds.colorise-icon(ui, premium, $theme: $theme);
  }
}

.meta {
  &.news {
    @include ds.text-color(secondary, $theme: $theme);
    @include ds.typography(h6, $theme);
  }

  &.native {
    @include ds.text-color(secondary, $theme: $theme);
    @include ds.typography(h4, $theme);
    font-weight: 400;
  }
}

.label {
  &.news {
    text-transform: capitalize;
  }
}

.link {
  display: block;
}

.unlocked {
  @include ds.text-color(premium, $theme: $theme);
}
