$theme: ekonomi;
@use "design-system" as ds;

.breakingCard {
  @include ds.card($theme: $theme);
  box-sizing: border-box;
}

.link {
  @include ds.text-color(primary, $theme: $theme);
  display: flex;
  padding: ds.spacing() ds.spacing();
}

.separator {
  @include ds.text-color(brand, $theme: $theme);
  margin-right: ds.spacing(base);
}

.vignette {
  @include ds.typography(h9, $theme);
  line-height: 1.2;
  margin-right: ds.spacing(micro);
  white-space: nowrap;
}

.title {
  @include ds.typography(h7, $theme);
  line-height: 1.2;
  overflow-wrap: anywhere;
  word-break: break-word;
}
