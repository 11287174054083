$theme: ekonomi;
@use "design-system" as ds;

.loader {
  height: 50px;
}

.feedLoaderContainer {
  position: relative;
}

.feedLoadLimit {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30%;
  width: 100%;
  pointer-events: none;
  //Minimum height from bottom of page
  //where we should start loading new content
  max-height: 1000px;
  @media (min-width: ds.$screen-size--medium) {
    max-height: 1400px;
  }
}

.endOfFeed {
  min-height: ds.spacing("medium-increased");
  @media (min-width: ds.$screen-size--medium) {
    min-height: ds.spacing("large-increased");
  }
}
