$theme: ekonomi;
@use "design-system" as ds;

.icon {
  display: flex;
  @include ds.colorise-icon(text, primary, $theme: $theme);

  & img {
    @include ds.darkmode {
      // Hack to make dark image icons whitish in dark mode
      filter: invert(100%);
    }
  }

  & svg {
    overflow: visible;
    pointer-events: all;
  }
}
