$theme: ekonomi;
@use "design-system" as ds;

.backdrop {
  @include ds.background(secondary, $theme: $theme);
  opacity: 0.9;
  z-index: ds.$z-index--cover;
  cursor: pointer;
}

.articleModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  pointer-events: none;
  z-index: ds.$z-index--modal;

  @media (min-width: ds.$screen-size--small) {
    top: ds.spacing(medium);
    bottom: ds.spacing(medium);
    right: ds.spacing(base);
    left: ds.spacing(base);
  }
}

.articleModalContent {
  max-height: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: all;

  @media (min-width: ds.$screen-size--small) {
    gap: ds.spacing();
    justify-content: center;
  }
}

.articleModalContentMain {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;

  @media (min-width: ds.$screen-size--small) {
    max-width: ds.$content-width--max;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }
}

.articleModalContentSecondary {
  display: none;
  height: 100%;

  @media (min-width: ds.$screen-size--medium) {
    display: block;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }
}

.inlineArticleHeader {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include ds.border(divider--subtle, $b: 1px, $theme: $theme);
  height: ds.spacing(large);
}

.inlineArticleHeaderTitle {
  flex-grow: 1;
  @include ds.typography(overline, $theme: $theme);
  text-align: center;

  @media (min-width: ds.$screen-size--small) {
    display: block;
  }
}

.inlineArticleHeaderTools {
  display: flex;
  flex-direction: row;
  width: 50px;
  gap: 4px;
  flex-grow: 1;
}

.inlineArticleHeaderToolsRight {
  justify-content: right;
  padding-right: ds.spacing(tiny);
}

.inlineArticle {
  @include ds.background(primary, $theme: $theme);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  cursor: auto;

  @media (min-width: ds.$screen-size--small) {
    border-radius: ds.$border-radius--default;
  }
}

.inlineArticleContent {
  flex-grow: 1;
  min-height: 200px;
  overflow-x: clip;
  overflow-y: auto;

  &.fullHeight {
    min-height: 100vh;
  }
}

.sponsoredBanner {
  position: sticky;
  top: 0;
  z-index: 1;
}

.hidden {
  visibility: hidden;
  width: 250px;
  height: 600px;
}
